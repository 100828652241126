import request from '@/utils/request'
import { param2Obj } from '@/utils'

// 优惠券
export function getCouponList(data) {
  return request({
    url: '/seller/coupon/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function getCouponGoods(data) {
  return request({
    url: '/seller/coupon/pageGoods',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchCoupon(data) {
  return request({
    url: '/seller/coupon/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function createCoupon(data) {
  return request({
    url: '/seller/coupon/add',
    method: 'post',
    data: param2Obj(data)
  })
}

export function updateCoupon(data) {
  return request({
    url: '/seller/coupon/edit',
    method: 'post',
    data: param2Obj(data)
  })
}

export function closureCoupon(data) {
  return request({
    url: '/seller/coupon/end',
    method: 'post',
    data: param2Obj(data)
  })
}

export function deleteCoupon(data) {
  return request({
    url: '/seller/coupon/delete',
    method: 'post',
    data: param2Obj(data)
  })
}
