<template>
    <div class="seller-coupon">
        <el-form inline class="page-query">
            <el-form-item>
                <el-select v-model="listQuery.status" @change="handleScreenTypeChange">
                    <el-option v-for="(item, index) in statusOption" :key="index" :label="item.label"
                        :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker v-model="datetimes" type="daterange" :range-separator="$t('seller.unit.to')"
                    :start-placeholder="$t('seller.placeholder.startDate')"
                    :end-placeholder="$t('seller.placeholder.endDate')" value-format="timestamp">
                </el-date-picker>
            </el-form-item>
            <!-- <el-form-item>
                <el-input v-model="listQuery.coupon_id" clearable
                    :placeholder="$t('seller.placeholder.couponId')"></el-input>
            </el-form-item> -->
            <el-form-item>
                <el-input v-model="listQuery.name" clearable :placeholder="$t('seller.placeholder.couponText')"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="listQuery.denom" clearable
                    :placeholder="$t('seller.placeholder.denomination')"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="getList">{{ $t('seller.actions.search') }}</el-button>
            </el-form-item>
        </el-form>
        <el-table v-loading="listLoading" :data="pageList" class="seller-table">
            <el-table-column :label="$t('seller.tableHead.channel')" min-width="100px">
                <template slot-scope="scope">
                    <div>{{ scope.row.name }}</div>
                    <div style="opacity: .6">
                        <el-tag
                            :type="scope.row.channel_type ? scope.row.channel_type : 0 | typeFilter({ 0: 'success', 1: 'danger' })"
                            effect="dark" size="mini">{{ scope.row.channel_type ? scope.row.channel_type : 0 | typeFilter({ 0: '全网自动推广', 1: '客户关系管理' }) }}</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('seller.tableHead.status')" prop="status_desc">
            </el-table-column>
            <el-table-column :label="$t('seller.tableHead.denomination')">
                <template slot-scope="scope">{{ scope.row.money }}{{ $t('seller.unit.yuan') }}</template>
            </el-table-column>
            <el-table-column :label="$t('seller.tableHead.threshold')">
                <template slot-scope="scope">{{ $t('seller.unit.full') }}{{ scope.row.quota }}{{ $t('seller.unit.yuan') }}</template>
            </el-table-column>
            <el-table-column :label="$t('seller.tableHead.useTime')" min-width="100px">
                <template slot-scope="scope">
                    <div>{{ $t('seller.unit.start') + ':' }}{{ scope.row.use_begin_time | parseTime('{y}-{m}-{d}') }}</div>
                    <div>{{ $t('seller.unit.stop') + ':' }}{{ scope.row.use_end_time | parseTime('{y}-{m}-{d}') }}</div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('seller.tableHead.limitedCollar')" prop="limit_give_num">
                <template slot-scope="scope">
                    <template v-if="scope.row.limit_give_num > 0">{{ scope.row.limit_give_num }}</template>
                    <template v-else>{{ $t('seller.coupon.unlimited') }}</template>
                </template>
            </el-table-column>
            <el-table-column :label="$t('seller.tableHead.circulation')" prop="give_num">
            </el-table-column>
            <el-table-column :label="$t('seller.tableHead.received')" prop="receive_num">
            </el-table-column>
            <el-table-column :label="$t('seller.tableHead.handle')">
                <template slot-scope="scope">
                    <el-button type="text"
                        @click="$router.push({ name: 'couponInfo', params: { type: scope.row.status_desc !== '已结束' ? 'edit' : 'view', id: scope.row.coupon_id } })">{{ scope.row.status_desc !== '已结束' ? $t('seller.actions.modify') : $t('seller.actions.view') }}</el-button>
                    <el-button v-if="scope.row.status_desc !== '已结束'" type="text"
                        @click="handleRowEnd(scope.row.coupon_id)">{{ $t('seller.actions.end') }}</el-button>
                    <el-button v-else type="text" @click="handleRowDelete(scope.row.coupon_id)">{{ $t('seller.actions.delete') }}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination v-show="pageTotal > 0" :total="pageTotal" :page.sync="pageCurr" :limit.sync="pageSize"
            @pagination="getList"></pagination>
    </div>
</template>

<script>
import Pagination from '@/components/seller/pagination'
import { getCouponList, closureCoupon, deleteCoupon } from '@/api/seller/coupon'
export default {
    components: { Pagination },
    data() {
        return {
            listLoading: false,
            datetimes: '',
            listQuery: {
                status: '0',
                // coupon_id: '',
                name: '',
                denom: '',
            },
            pageList: [],
            pageTotal: 0,
            pageCurr: 1,
            pageSize: 10,
            statusOption: this.$t('seller.coupon.statusOption')
        }
    },
    created() {
        this.getList()
    },
    methods: {
        getList() {
            this.listLoading = true
            let param = {
                ...this.listQuery,
                type: 2,
                page_index: (this.pageCurr - 1) * this.pageSize,
                page_size: this.pageSize
            };
            if (this.datetimes) {
                param.use_begin_time = this.datetimes[0] / 1000;
                param.use_end_time = this.datetimes[1] / 1000;
            }
            getCouponList(param).then(response => {
                this.pageList = response.data.list;
                this.pageTotal = parseInt(response.data.total);
                this.listLoading = false
            })
        },
        handleScreenTypeChange() {
            this.pageCurr = 1;
            this.getList();
        },
        handleRowEnd(id) {
            this.$confirm(this.$t('seller.confirm.endCollection'), this.$t('seller.confirm.title'), {
                confirmButtonText: this.$t('seller.actions.confirm'),
                cancelButtonText: this.$t('seller.actions.cancel'),
                type: 'warning'
            }).then(() => {
                closureCoupon({ coupon_id: id }).then(() => {
                    this.getList();
                })
            }).catch(() => { })
        },
        handleRowDelete(id) {
            this.$confirm(this.$t('seller.confirm.deleteText3'), this.$t('seller.confirm.title'), {
                confirmButtonText: this.$t('seller.actions.confirm'),
                cancelButtonText: this.$t('seller.actions.cancel'),
                type: 'warning'
            }).then(() => {
                let param = {
                    column: 'is_delete',
                    coupon_id: id,
                    value: '1'
                }
                deleteCoupon(param).then(() => {
                    this.getList();
                })
            }).catch(() => { })
        }
    }
}
</script>

<style lang="scss" scoped></style>