<template>
	<el-scrollbar class="seller-scrollbar">
		<el-card class="seller-card">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">{{ $t('seller.breadcrumb.seller') }}</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ name: 'marketingtools' }">{{ $t('seller.breadcrumb.marketPlatform') }}</el-breadcrumb-item>
				<el-breadcrumb-item>{{ $t('seller.breadcrumb.coupon') }}</el-breadcrumb-item>
			</el-breadcrumb>
		</el-card>

		<el-card class="seller-card">
			<template slot="header">{{ $t('seller.coupon.title') }}</template>
			<div class="coupon-tools">
				<router-link class="item" :to="{ name: 'couponCreate' }">
					<img class="item-pic" src="@/assets/images/seller/market-tps-011.png">
					<div class="item-main">
						<div class="name">{{ $t('seller.coupon.shop') }}</div>
						<div class="action"><el-button type="primary" size="mini">{{ '+' + $t('seller.actions.createCoupon') }}</el-button></div>
					</div>
				</router-link>
				<router-link class="item" :to="{ name: 'couponCreate', query: { type: '2' } }">
					<img class="item-pic" src="@/assets/images/seller/market-tps-012.png">
					<div class="item-main">
						<div class="name">{{ $t('seller.coupon.goods') }}</div>
						<div class="action"><el-button type="primary" size="mini">{{ '+' + $t('seller.actions.createCoupon') }}</el-button></div>
					</div>
				</router-link>
				<router-link class="item" :to="{ name: 'couponCreateShare' }">
					<img class="item-pic" src="@/assets/images/seller/market-tps-013.png">
					<div class="item-main">
						<div class="name">{{ $t('seller.coupon.share') }}</div>
						<div class="action"><el-button type="primary" size="mini">{{ '+' + $t('seller.actions.createCoupon') }}</el-button></div>
					</div>
				</router-link>
			</div>
		</el-card>

		<el-card class="seller-card card-tabs">
			<el-tabs slot="header" v-model="activeTabs" @tab-click="handleClickTabs" class="seller-tabs">
				<el-tab-pane :label="$t('seller.coupon.shop')" name="1"></el-tab-pane>
				<el-tab-pane :label="$t('seller.coupon.goods')" name="2"></el-tab-pane>
				<el-tab-pane :label="$t('seller.coupon.share')" name="3"></el-tab-pane>
			</el-tabs>
			<shop-coupon v-if="activeTabs === '1'" />
			<goods-coupon v-if="activeTabs === '2'" />
			<share-coupon v-if="activeTabs === '3'" />
		</el-card>
	</el-scrollbar>
</template>

<script>
import ShopCoupon from './components/shop'
import GoodsCoupon from './components/goods'
import ShareCoupon from './components/share'
export default {
	components: {
		ShopCoupon,
		GoodsCoupon,
		ShareCoupon
	},
	data() {
		return {
			activeTabs: this.$route.query.type?.toString() || '1',
		}
	},
	methods: {
		handleClickTabs(tab, event) {
			this.$router.push({ query: { type: tab.name } })
		},
	}
}
</script>

<style lang="scss" scoped>
.coupon-tools {
	display: flex;
	.item {
		display: flex;
		width: 210px;
		margin-right: 20px;
		margin-bottom: 20px;

		.item-pic {
			width: 80px;
			height: 80px;
			object-fit: cover;
		}

		.item-main {
			flex: 1;
			width: 0;
			padding: 2px 8px 0;

			.name {
				color: #666666;
				height: 22px;
				line-height: 22px;
				font-size: 16px;
			}

			.action {
				margin-top: 28px;
			}
		}
	}
}
</style>
