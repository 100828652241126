<template>
    <div class="seller-coupon">
        <el-form inline class="page-query">
            <el-form-item>
                <el-select v-model="listQuery.status" @change="handleScreenTypeChange">
                    <el-option v-for="(item, index) in statusOption" :key="index" :label="item.label"
                        :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker v-model="datetimes" type="daterange" :range-separator="$t('seller.unit.to')"
                    :start-placeholder="$t('seller.placeholder.startDate')"
                    :end-placeholder="$t('seller.placeholder.endDate')" value-format="timestamp">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-input v-model="listQuery.name" clearable
                    :placeholder="$t('seller.placeholder.couponText')"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="listQuery.denom" clearable
                    :placeholder="$t('seller.placeholder.denomination')"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="getList">{{ $t('seller.actions.search') }}</el-button>
            </el-form-item>
        </el-form>
        <el-table v-loading="listLoading" :data="pageList" class="seller-table">
            <el-table-column :label="$t('seller.screen.campaignName')" min-width="100px">
                <template slot-scope="scope">
                    <div class="fission-cell">{{ scope.row.name }}</div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('seller.tableHead.role')" min-width="100px">
                <template slot-scope="">
                    <div class="fission-cell-row">{{ $t('seller.coupon.sharers') }}</div>
                    <div class="fission-cell-row">{{ $t('seller.coupon.sharedBy') }}</div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('seller.tableHead.status')">
                <template slot-scope="scope">
                    <div class="fission-cell-row">{{ scope.row.status_desc }}</div>
                    <div class="fission-cell-row">{{ scope.row.sub_coupon.status_desc }}</div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('seller.tableHead.denomination')">
                <template slot-scope="scope">
                    <div class="fission-cell-row">{{ scope.row.money }}{{ $t('seller.unit.yuan') }}</div>
                    <div class="fission-cell-row">{{ scope.row.sub_coupon.money }}{{ $t('seller.unit.yuan') }}</div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('seller.tableHead.threshold')">
                <template slot-scope="scope">
                    <div class="fission-cell-row">{{ $t('seller.unit.full') }}{{ scope.row.quota }}{{ $t('seller.unit.yuan') }}</div>
                    <div class="fission-cell-row">{{ $t('seller.unit.full') }}{{ scope.row.sub_coupon.quota }}{{ $t('seller.unit.yuan') }}</div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('seller.tableHead.couponType')">
                <template slot-scope="scope">
                    <div class="fission-cell-row">{{ scope.row.sub_type | typeFilter($t('seller.filters.couponType')) }}</div>
                    <div class="fission-cell-row">{{ scope.row.sub_coupon.sub_type | typeFilter($t('seller.filters.couponType')) }}</div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('seller.tableHead.useTime')" min-width="145">
                <template slot-scope="scope">
                    <div class="fission-cell-row">
                        <div>{{ $t('seller.unit.start') + ':' }}{{ scope.row.use_begin_time | parseTime() }}</div>
                        <div>{{ $t('seller.unit.stop') + ':' }}{{ scope.row.use_end_time | parseTime() }}</div>
                    </div>
                    <div class="fission-cell-row">
                        <div>{{ $t('seller.unit.start') + ':' }}{{ scope.row.sub_coupon.use_begin_time | parseTime() }}</div>
                        <div>{{ $t('seller.unit.stop') + ':' }}{{ scope.row.sub_coupon.use_end_time | parseTime() }}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('seller.tableHead.limitedCollar')" prop="limit_give_num">
                <template slot-scope="scope">
                    <div class="fission-cell-row">{{ scope.row.limit_give_num }}{{ $t('seller.unit.sheet') }}</div>
                    <div class="fission-cell-row">{{ scope.row.sub_coupon.limit_give_num }}{{ $t('seller.unit.sheet') }}</div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('seller.tableHead.exfiltrateTime')">
                <template slot-scope="scope">
                    <div class="fission-cell-row">{{ scope.row.expire_reminder | parseTime() }}</div>
                    <div class="fission-cell-row"></div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('seller.tableHead.circulation')">
                <template slot-scope="scope">
                    <div class="fission-cell-row">{{ scope.row.give_num }}</div>
                    <div class="fission-cell-row">{{ scope.row.sub_coupon.give_num }}</div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('seller.tableHead.received')">
                <template slot-scope="scope">
                    <div class="fission-cell-row">{{ scope.row.receive_num }}</div>
                    <div class="fission-cell-row">{{ scope.row.sub_coupon.receive_num }}</div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('seller.tableHead.handle')" width="90">
                <template slot-scope="scope">
                    <div class="fission-cell-row">
                        <div>
                            <el-button type="text" @click="$router.push({ name: 'couponShareInfo', params: { type: scope.row.status_desc !== '已结束' ? 'edit' : 'view', id: scope.row.coupon_id } })">{{ scope.row.status_desc !== '已结束' ? $t('seller.actions.modify') : $t('seller.actions.view') }}</el-button>
                            <el-button v-if="scope.row.status_desc !== '已结束'" type="text" @click="handleRowEnd(scope.row.coupon_id)">{{ $t('seller.actions.end') }}</el-button>
                            <el-button v-else type="text" @click="handleRowDelete(scope.row.coupon_id)">{{ $t('seller.actions.delete') }}</el-button>
                        </div>
                    </div>
                    <div class="fission-cell-row">&nbsp;</div>
                </template>
            </el-table-column>
        </el-table>
        <pagination v-show="pageTotal > 0" :total="pageTotal" :page.sync="pageCurr" :limit.sync="pageSize"
            @pagination="getList"></pagination>
    </div>
</template>

<script>
import Pagination from '@/components/seller/pagination'
import { getCouponList, closureCoupon, deleteCoupon } from '@/api/seller/coupon'
export default {
    components: { Pagination },
    data() {
        return {
            listLoading: false,
            datetimes: '',
            listQuery: {
                status: '0',
                coupon_id: '',
                name: '',
                denom: '',
            },
            pageList: [],
            pageTotal: 0,
            pageCurr: 1,
            pageSize: 10,
            statusOption: this.$t('seller.coupon.statusOption')
        }
    },
    created() {
        this.getList()
    },
    methods: {
        getList() {
            this.listLoading = true
            let param = {
                ...this.listQuery,
                type: 3,
                page_index: (this.pageCurr - 1) * this.pageSize,
                page_size: this.pageSize
            };
            if (this.datetimes) {
                param.use_begin_time = this.datetimes[0] / 1000;
                param.use_end_time = this.datetimes[1] / 1000;
            }
            getCouponList(param).then(response => {
                this.pageList = response.data.list;
                this.pageTotal = parseInt(response.data.total);
                this.listLoading = false
            })
        },
        handleScreenTypeChange() {
            this.pageCurr = 1;
            this.getList();
        },
        handleRowEnd(id) {
            this.$confirm(this.$t('seller.confirm.endCollection'), this.$t('seller.confirm.title'), {
                confirmButtonText: this.$t('seller.actions.confirm'),
                cancelButtonText: this.$t('seller.actions.cancel'),
                type: 'warning'
            }).then(() => {
                closureCoupon({ coupon_id: id }).then(() => {
                    this.getList();
                })
            }).catch(() => { })
        },
        handleRowDelete(id) {
            this.$confirm(this.$t('seller.confirm.deleteText3'), this.$t('seller.confirm.title'), {
                confirmButtonText: this.$t('seller.actions.confirm'),
                cancelButtonText: this.$t('seller.actions.cancel'),
                type: 'warning'
            }).then(() => {
                let param = {
                    column: 'is_delete',
                    coupon_id: id,
                    value: '1'
                }
                deleteCoupon(param).then(() => {
                    this.getList();
                })
            }).catch(() => { })
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .seller-table {
    .cell {
        padding: 0;
        .fission-cell-row {
            padding: 0 10px;
            font-size: 12px;
            height: 66px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &:first-of-type {
                border-bottom: 1px solid #dcdee3;
            }
        }

        .fission-cell {
            padding: 0 10px;
        }
    }
}
</style>